import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "N-body Collision Simulation with React, D3, and MobX",
  "description": "Collision detection is one of those problems that's easy in theory but hard in practice. Even between ideal circles. Here's how to do it",
  "date": "2017-03-16T08:00:00.000Z",
  "published": "2017-03-16T08:00:00.000Z",
  "image": "../../../images/articles/screenshot-1591716513625.png"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Collision detection is one of those problems that`}{`'`}{`s easy in theory but hard in practice. Even between ideal circles.`}</p>
    <p>{`All you have to do is:`}</p>
    <ol>
      <li parentName="ol">{`For every item, find all items with which it intersects`}</li>
      <li parentName="ol">{`Change its movement vector so it reacts to the collision`}</li>
      <li parentName="ol">{`Potentially change vectors of other items involved in collision`}</li>
    </ol>
    <p>{`This is called `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Collision_detection#A_posteriori_.28discrete.29_versus_a_priori_.28continuous.29"
      }}>{`a posteriori collision detection`}</a>{`. It detects collisions `}<em parentName="p">{`after`}</em>{` they happen and corrects in the next animation frame. If items move very fast, they can pass through each other.`}</p>
    <p>{`Another approach is called a priori collision detection. That predicts collisions `}<em parentName="p">{`before`}</em>{` they happen. It`}{`'`}{`s more correct, but it’s hard to implement because predicting when two items will collide is, in theory, an algorithm with infinite steps. Wikipedia says the problem doesn`}{`'`}{`t have a `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Closed-form_expression"
      }}>{`closed-form solution`}</a>{`.`}</p>
    <p>{`As per this `}<a parentName="p" {...{
        "href": "https://wwwx.cs.unc.edu/~geom/papers/documents/dissertations/lin93.pdf"
      }}>{`159 page thesis on collision detection`}</a>{`, collision detection is `}{`\\`}{`important.`}</p>
    <blockquote>
      <p parentName="blockquote">{`The problem of collision detection or contact determination between two or more objects is fundamental to computer animation, physical-based modeling, molecular modeling, computer simulated environments (e.g. virtual environments) and robot motion planning as well. (Depending on the content of applications, it is also called with many different names, such as interference detection, clash detection, intersection tests, etc.)`}</p>
    </blockquote>
    <p>{`Indeed. It`}{`'`}{`s also fun to build. You can play with the final solution in this `}<a parentName="p" {...{
        "href": "https://swizec.github.io/declarative-canvas-react-konva/"
      }}>{`live example`}</a>{`.`}</p>
    <p>{`Here is my first attempt ?`}</p>
    <p><img parentName="p" {...{
        "src": "/b8e0960f2a85a2e294829d363f954e26/blog-wp-content-uploads-2017-03-base-collision.gif",
        "alt": null
      }}></img></p>
    <p>{`? Hmmmm… the two marbles do bounce off each other, but that`}{`'`}{`s not quite right. They come too close together before bouncing.`}</p>
    <p>{`On my second attempt, I used `}<a parentName="p" {...{
        "href": "https://github.com/d3/d3-quadtree"
      }}>{`D3v4`}{`'`}{`s quadtree`}</a>{` to detect the collision. Quadtrees subdivide a space based on a list of coordinates. In my case, it’s a list of marbles. You can then perform fast lookups for `}<em parentName="p">{`"`}{`Which items are within radius R of coordinates (x, y)?”`}</em>{`.`}</p>
    <p>{`With some finagling, we can use a quadtree to reduce our n-body collision problem to a series of 2-body collision problems. Each item collides only with its nearest neighbor.`}</p>
    <p><img parentName="p" {...{
        "src": "/d1cc3324f64d34e54f1021ff733d5c0b/blog-wp-content-uploads-2017-03-quadtree-collision.gif",
        "alt": null
      }}></img></p>
    <p>{`Better. Mr. Quadtree catches the collision when it happens and both marbles bounce off. After the collision, they travel in the opposite direction of where they were going before.`}</p>
    <p>{`But a collision should deflect marbles, not just bounce them. So I used a `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Dot_product"
      }}>{`dot product`}</a>{` to calculate new vectors. Thanks to `}<a parentName="p" {...{
        "href": "https://twitter.com/air_hadoken/status/837363064005623810"
      }}>{`@air_hadoken`}</a>{` for showing me some of his old code. It helped a lot ^`}{`_`}{`^`}</p>
    <p><img parentName="p" {...{
        "src": "/e5b5feb0e13785c73df29fab079e157c/blog-wp-content-uploads-2017-03-dotproduct-collision.gif",
        "alt": null
      }}></img></p>
    <p>{`Marvelous! Direction vectors get deflected, marbles bounce off each other, and something is still wrong. My collision correction algorithm doesn`}{`'`}{`t account for mass.`}</p>
    <p>{`Real marbles have mass. To make life easier, I decided all marbles should have the same mass. This means each marble involved in a collision gets half the combined speed involved in the impact.`}</p>
    <p><img parentName="p" {...{
        "src": "/87524a6edf10aaba002fc174c62670e0/blog-wp-content-uploads-2017-03-mass-collision.gif",
        "alt": null
      }}></img></p>
    <p>{`?… hmmm… I don`}{`'`}{`t think marbles are that sticky.`}</p>
    <p>{`I started tweaking parameters, changing coefficients, and tweaking multipliers. I came up with this:`}</p>
    <div><div parentName="div" {...{
        "className": "static-tweet-embed"
      }}>{`
        `}<a parentName="div" {...{
          "className": "author",
          "href": "https://t.co/VuU1lFnIe7"
        }}><img parentName="a" {...{
            "src": "https://pbs.twimg.com/profile_images/1423736293385662466/AnF0Fsi6_normal.jpg",
            "loading": "lazy",
            "alt": "Swizec Teller writing a secret book avatar"
          }}></img><b parentName="a">{`Swizec Teller writing a secret book`}</b>{`@Swizec`}</a>{`
        `}<blockquote parentName="div">{`This is how physics works, right? `}</blockquote>{`
        `}<div parentName="div" {...{
          "className": "media"
        }}><img parentName="div" {...{
            "src": "https://pbs.twimg.com/ext_tw_video_thumb/841529452207079424/pu/img/wE02WbuERE_EHheG.jpg",
            "width": "100%",
            "loading": "lazy",
            "alt": "Tweet media"
          }}></img></div>{`
        `}<div parentName="div" {...{
          "className": "time"
        }}><a parentName="div" {...{
            "href": "https://twitter.com/Swizec/status/841529688002449409"
          }}>{`6:01:27 AM – 3/14/2017`}</a></div>{`
        `}<div parentName="div" {...{
          "className": "stats"
        }}><a parentName="div" {...{
            "href": "https://twitter.com/intent/like?tweet_id=841529688002449409",
            "className": "like"
          }}><svg parentName="a" {...{
              "viewBox": "0 0 24 24",
              "className": "r-m0bqgq r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-bnwqim r-1plcrui r-lrvibr",
              "style": {}
            }}><g parentName="svg"><path parentName="g" {...{
                  "d": "M12 21.638h-.014C9.403 21.59 1.95 14.856 1.95 8.478c0-3.064 2.525-5.754 5.403-5.754 2.29 0 3.83 1.58 4.646 2.73.814-1.148 2.354-2.73 4.645-2.73 2.88 0 5.404 2.69 5.404 5.755 0 6.376-7.454 13.11-10.037 13.157H12zM7.354 4.225c-2.08 0-3.903 1.988-3.903 4.255 0 5.74 7.034 11.596 8.55 11.658 1.518-.062 8.55-5.917 8.55-11.658 0-2.267-1.823-4.255-3.903-4.255-2.528 0-3.94 2.936-3.952 2.965-.23.562-1.156.562-1.387 0-.014-.03-1.425-2.965-3.954-2.965z"
                }}></path></g></svg>{`11`}</a>{` `}<a parentName="div" {...{
            "href": "https://twitter.com/Swizec/status/841529688002449409",
            "className": "reply"
          }}><svg parentName="a" {...{
              "viewBox": "0 0 24 24",
              "className": "r-m0bqgq r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-bnwqim r-1plcrui r-lrvibr"
            }}><g parentName="svg"><path parentName="g" {...{
                  "d": "M14.046 2.242l-4.148-.01h-.002c-4.374 0-7.8 3.427-7.8 7.802 0 4.098 3.186 7.206 7.465 7.37v3.828c0 .108.044.286.12.403.142.225.384.347.632.347.138 0 .277-.038.402-.118.264-.168 6.473-4.14 8.088-5.506 1.902-1.61 3.04-3.97 3.043-6.312v-.017c-.006-4.367-3.43-7.787-7.8-7.788zm3.787 12.972c-1.134.96-4.862 3.405-6.772 4.643V16.67c0-.414-.335-.75-.75-.75h-.396c-3.66 0-6.318-2.476-6.318-5.886 0-3.534 2.768-6.302 6.3-6.302l4.147.01h.002c3.532 0 6.3 2.766 6.302 6.296-.003 1.91-.942 3.844-2.514 5.176z"
                }}></path></g></svg>{`2`}</a></div>{`
    `}</div></div>
    <p>{`Click play. A gif wouldn`}{`'`}{`t do it justice.`}</p>
    <p>{`Turns out, speed is definitely not additive. That looks more like a fission simulation at the atom level than a simulation of marbles hitting each other. ?`}</p>
    <p>{`After some more tweaking, I think I got it:`}</p>
    <p><img parentName="p" {...{
        "src": "/7ee2790ae985c858039d1bf78a414489/blog-wp-content-uploads-2017-03-better-collisions.gif",
        "alt": null
      }}></img></p>
    <p><em parentName="p">{`In general`}</em>{`, marbles bounce off each other, have their vectors deflected, slow down due to friction, and share mass in a collision. I don`}{`'`}{`t know if it`}{`'`}{`s a physically correct simulation, but it looks good enough to me.`}</p>
    <p>{`Some marbles still stick.`}</p>
    <h2 {...{
      "id": "why-some-marbles-still-stick-together"
    }}>{`Why some marbles still stick together`}</h2>
    <p>{`Marbles sticking together can happen due to one of three reasons:`}</p>
    <ol>
      <li parentName="ol">{`User overlaid marbles manually`}</li>
      <li parentName="ol">{`A marble `}<em parentName="li">{`actually`}</em>{` collides with multiple marbles within the same 16ms frame. Only one of the collisions is detected`}</li>
      <li parentName="ol">{`A fast marble hits a small marble in such a way that their cumulative speed doesn`}{`'`}{`t produce escape velocity`}</li>
    </ol>
    <p>{`The 1st case often goes away when you release the mouse. Sometimes it does not, depending on angle of approach.`}</p>
    <p>{`The 2nd case is unsolvable when using quadtrees for collision detection.`}</p>
    <p>{`The 3rd case can be solved by applying the collision step until all collisions are resolved. This `}<em parentName="p">{`might`}</em>{` help with the 2nd case too ?`}</p>
    <p><img parentName="p" {...{
        "src": "/5495f1c8cb32ff8d4b5ec48be7d10915/blog-wp-content-uploads-2017-03-iterative-application.gif",
        "alt": null
      }}></img></p>
    <p>{`Well… there`}{`'`}{`s no stickiness… but no.`}</p>
    <hr></hr>
    <h2 {...{
      "id": "heres-how-it-works"
    }}>{`Here`}{`'`}{`s how it works`}</h2>
    <p>{`The marbles are rendered on `}<inlineCode parentName="p">{`<canvas>`}</inlineCode>{` using a combination of React, react-konva, and Konva. Each is rendered declaratively from an array of `}<inlineCode parentName="p">{`(x, y)`}</inlineCode>{` positions that change with each step of the simulation.`}</p>
    <p>{`You can read about the rendering part in my `}<a parentName="p" {...{
        "href": "https://swizec.com/blog/declarative-canvas-animation-react-konva/swizec/7443"
      }}>{`Declarative canvas with React and Konva`}</a>{` article.`}</p>
    <p>{`Since then, I have moved all logic into a MobX store called `}<inlineCode parentName="p">{`Physics`}</inlineCode>{`. You can see the `}<a parentName="p" {...{
        "href": "https://github.com/Swizec/declarative-canvas-react-konva"
      }}>{`full code on Github`}</a>{`. The interesting bits for collision detection are in the `}<a parentName="p" {...{
        "href": "https://github.com/Swizec/declarative-canvas-react-konva/tree/master/src/logic"
      }}>{`src/logic`}</a>{` directory.`}</p>
    <p>{`Our general approach goes like this:`}</p>
    <ol>
      <li parentName="ol">{`Have an observable array of marbles`}</li>
      <li parentName="ol">{`Run a `}<inlineCode parentName="li">{`simulationStep`}</inlineCode>{` on each `}<inlineCode parentName="li">{`requesAnimationFrame`}</inlineCode>{` using `}<inlineCode parentName="li">{`d3.timer`}</inlineCode></li>
      <li parentName="ol">{`Change marble positions and speed`}</li>
      <li parentName="ol">{`MobX observables and observers trigger re-renders of marbles that move`}</li>
    </ol>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://github.com/Swizec/declarative-canvas-react-konva/blob/master/src/logic/Physics.js"
      }}><inlineCode parentName="a">{`Physics.js`}</inlineCode></a>{` file is 160 lines, so let me show you just the interesting part: `}<inlineCode parentName="p">{`@action simulationStep`}</inlineCode>{`. It handles collision detection and the aftermath of each collision.`}</p>
    <h2 {...{
      "id": "simulationstep--where-collisions-collision"
    }}>{`simulationStep – where collisions collision`}</h2>
    <pre><code parentName="pre" {...{}}>{`@action simulationStep() {
    const { width, height, MarbleR } = this;

    const moveMarble = ({x, y, vx, vy, id}) => {
        let _vx = ((x+vx < MarbleR) ? -vx : (x+vx > width-MarbleR) ? -vx : vx)*.99,
            _vy = ((y+vy < MarbleR) ? -vy : (y+vy > height-MarbleR) ? -vy : vy)*.99;

        // nearest marble is a collision candidate
        const subdividedSpace = quadtree().extent([[-1, -1],
                                                   [this.width+1, this.height+1]])
                                          .x(d => d.x)
                                          .y(d => d.y)
                                          .addAll(this.marbles
                                                      .filter(m => id !== m.id)),
              candidate = subdividedSpace.find(x, y, MarbleR*2);

        if (candidate) {

            // borrowing @air_hadoken's implementation from here:
            // https://github.com/airhadoken/game_of_circles/blob/master/circles.js#L64
            const cx = candidate.x,
                  cy = candidate.y,
                  normx = cx - x,
                  normy = cy - y,
                  dist = (normx ** 2 + normy ** 2),
                  c = (_vx * normx + _vy * normy) / dist * 2.3;

            _vx = (_vx - c * normx)/2.3;
            _vy = (_vy - c * normy)/2.3;

            candidate.vx += -_vx;
            candidate.vy += -_vy;
            candidate.x += -_vx;
            candidate.y += -_vy;
        }

        return {
            x: x + _vx,
            y: y + _vy,
            vx: _vx,
            vy: _vy
        }
    };

    this.marbles.forEach((marble, i) => {
        const { x, y, vx, vy } = moveMarble(marble);

        this.marbles[i].x = x;
        this.marbles[i].y = y;
        this.marbles[i].vx = vx;
        this.marbles[i].vy = vy;
    });
}
`}</code></pre>
    <p>{`That`}{`'`}{`s a lot of code ?. Let`}{`'`}{`s break it down.`}</p>
    <p>{`You can think of it as a function and a loop. At the bottom, there is a `}<inlineCode parentName="p">{`.forEach`}</inlineCode>{` that applies a `}<inlineCode parentName="p">{`moveMarble`}</inlineCode>{` function to each marble.`}</p>
    <pre><code parentName="pre" {...{}}>{`    this.marbles.forEach((marble, i) => {
        const { x, y, vx, vy } = moveMarble(marble);

        this.marbles[i].x = x;
        this.marbles[i].y = y;
        this.marbles[i].vx = vx;
        this.marbles[i].vy = vy;
    });
`}</code></pre>
    <p>{`We iterate over the list of marbles, feed them into `}<inlineCode parentName="p">{`moveMarble`}</inlineCode>{`, get their new properties, and save them in the main marbles array. This might be unnecessary because of MobX. We `}<em parentName="p">{`should`}</em>{` be able to change them inside the loop and rely on MobX observables to do the heavy lifting.`}</p>
    <p>{`I wonder why I did it like this ? Maybe a leftover from before MobX?`}</p>
    <h3 {...{
      "id": "movemarble"
    }}>{`moveMarble`}</h3>
    <p><inlineCode parentName="p">{`moveMarble`}</inlineCode>{` is itself a hairy function. Things happen in 3 steps:`}</p>
    <ol>
      <li parentName="ol">{`Handle collisions with walls`}</li>
      <li parentName="ol">{`Find collision with closest other marble`}</li>
      <li parentName="ol">{`Handle collision with marble`}</li>
    </ol>
    <p><strong parentName="p">{`Handling collisions with walls happens`}</strong>{` in two lines of code. One per coordinate.`}</p>
    <pre><code parentName="pre" {...{}}>{`let _vx = ((x+vx < MarbleR) ? -vx : (x+vx > width-MarbleR) ? -vx : vx)*.99,
    _vy = ((y+vy < MarbleR) ? -vy : (y+vy > height-MarbleR) ? -vy : vy)*.99;
`}</code></pre>
    <p>{`Nested ternary expressions are kinda messy, but they’re good enough. If a marble is beyond any boundary, we reverse its direction. We `}<em parentName="p">{`always`}</em>{` apply a `}<inlineCode parentName="p">{`.99`}</inlineCode>{` friction coefficient so that marbles slow down.`}</p>
    <p><strong parentName="p">{`Finding collisions`}</strong>{` with the next closest marble happens via a quadtree. Since we don`}{`'`}{`t have too many marbles, we can afford to build a new quadtree from scratch every time.`}</p>
    <pre><code parentName="pre" {...{}}>{`// nearest marble is a collision candidate
const subdividedSpace = quadtree().extent([[-1, -1],
                                           [this.width+1, this.height+1]])
                                  .x(d => d.x)
                                  .y(d => d.y)
                                  .addAll(this.marbles
                                              .filter(m => id !== m.id)),
      candidate = subdividedSpace.find(x, y, MarbleR*2);
`}</code></pre>
    <p>{`We`}{`'`}{`re using `}<a parentName="p" {...{
        "href": "https://github.com/d3/d3-quadtree"
      }}><inlineCode parentName="a">{`d3-quadtree`}</inlineCode></a>{` for the quadtree implementation. It takes an `}<inlineCode parentName="p">{`extent`}</inlineCode>{`, which tells it how big our space is. It uses `}<inlineCode parentName="p">{`x`}</inlineCode>{` and `}<inlineCode parentName="p">{`y`}</inlineCode>{` accessors to get coordinates out of our marble objects, and we use `}<inlineCode parentName="p">{`addAll`}</inlineCode>{` to fill it with marbles.`}</p>
    <p>{`To avoid detecting each marble as colliding with itself, we take each marble out of our list before feeding the quadtree.`}</p>
    <p>{`Once we have a quadtree built out, we use `}<inlineCode parentName="p">{`.find`}</inlineCode>{` to look for the nearest marble within `}<inlineCode parentName="p">{`MarbleR*2`}</inlineCode>{` of the current marble. Which is exactly the one we`}{`'`}{`re colliding with! :)`}</p>
    <p><strong parentName="p">{`Handling collisions with marbles`}</strong>{` involves math. It’s the sort of thing you think you remember from high school and then suddenly realize you don`}{`'`}{`t when the time comes to use it.`}</p>
    <p>{`The code looks like this:`}</p>
    <pre><code parentName="pre" {...{}}>{`if (candidate) {

    // borrowing @air_hadoken's implementation from here:
    // https://github.com/airhadoken/game_of_circles/blob/master/circles.js#L64
    const cx = candidate.x,
          cy = candidate.y,
          normx = cx - x,
          normy = cy - y,
          dist = (normx ** 2 + normy ** 2),
          c = (_vx * normx + _vy * normy) / dist * 2.3;

    _vx = (_vx - c * normx)/2.3;
    _vy = (_vy - c * normy)/2.3;

    candidate.vx += -_vx;
    candidate.vy += -_vy;
    candidate.x += -_vx;
    candidate.y += -_vy;
}

return {
    x: x + _vx,
    y: y + _vy,
    vx: _vx,
    vy: _vy
}
`}</code></pre>
    <p>{`Ok, the `}<inlineCode parentName="p">{`return`}</inlineCode>{` statement isn`}{`'`}{`t about handling collisions. It updates the current marble.`}</p>
    <p>{`The rest kind of looks like magic. I implemented it and it looks like magic and I feel like I don`}{`'`}{`t `}<em parentName="p">{`really`}</em>{` understand it.`}</p>
    <p>{`You can think of `}<inlineCode parentName="p">{`[normx, normy]`}</inlineCode>{` as a vector that points from current marble to collision candidate. It gives us bounce direction. We use the `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Euclidean_distance"
      }}>{`euclidean distance`}</a>{` formula to calculate the length of this vector. It measures the distance between the centers of both marbles.`}</p>
    <p>{`Then we calculate the `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Dot_product"
      }}>{`dot product`}</a>{` between our marble`}{`'`}{`s speed vector and the collision direction vector. We normalize it by distance. Multiplying distance by `}<inlineCode parentName="p">{`2`}</inlineCode>{` accounts for there being two marbles in the collision. That extra `}<inlineCode parentName="p">{`.3`}</inlineCode>{` made the simulation look better.`}</p>
    <p>{`I fiddled with it :)`}</p>
    <p>{`Then we use the dot product scalar to adjust the marble`}{`'`}{`s speed vector. Dividing by `}<inlineCode parentName="p">{`2`}</inlineCode>{` takes into account that half the energy goes to the other marble. This is only true because we assume their masses are equal.`}</p>
    <p>{`Finally, we update the `}<inlineCode parentName="p">{`candidate`}</inlineCode>{` marble and make sure it bounces off as well. We do it additively because that`}{`'`}{`s how it happens in real life.`}</p>
    <p>{`Two marbles traveling towards each other in exactly opposite directions with exactly the same speed will stop dead and stay there. As soon as there`}{`'`}{`s any misalignment, deflection happens. If one is stationary, it starts moving. If it`}{`'`}{`s moving in the same direction, it speeds up… etc.`}</p>
    <p>{`The end result is a decent-looking simulation of billiards.`}</p>
    <p><a parentName="p" {...{
        "href": "https://swizec.github.io/declarative-canvas-react-konva/"
      }}><img parentName="a" {...{
          "src": "/7ee2790ae985c858039d1bf78a414489/blog-wp-content-uploads-2017-03-better-collisions-1.gif",
          "alt": null
        }}></img></a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      